import 'core-js/stable';
import Alpine from 'alpinejs'
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, updateProfile } from "firebase/auth";
import firebaseConfig from "./firebaseconf";
import AirDatepicker from "air-datepicker"
import 'air-datepicker/air-datepicker.css';
import en from "air-datepicker/locale/en";
import currency from 'currency.js';
import confetti from 'canvas-confetti';
import { Chart } from 'chart.js/auto';
import { convertDateToYMD, formatDateForGraph, convertMonthYearToDate, getFirebaseErrorMessage, timeAgo, convertMonthYearToYMD, convertMonthDayYearToDate, dropdownToDate, copyToClipboard, getMonthName, assetSortFunctions, usernameValidation, PORTFOLIO_TTL } from './utils.js'
import { TTLStorage } from './ttl.js';

const USD = value => currency(value, { symbol: '', precision: 2 }).format();

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Authentication
const firebase_auth = getAuth(app);
export default firebase_auth;

window.Alpine = Alpine;

window.app = function () {
    let chartBig;
    let pieChart;
    let smallChart;

    return {
        email: '',
        password: '',
        authError: '',

        netWorth: 0,
        netWorthLastUpdated: null,
        networthCalculating: false,

        currentUser: null, // it has to be like this,
        isAdmin: false, // used only to debug portfolios (only view)
        loadingDone: false,

        // uploading and processing portfolio
        uploadProgress: 0,
        processingProgress: 0,
        uploadProgressVisible: false,
        processingProgressVisible: false,
        imagePreview: null,
        fileForDebugging: null,

        // -- dashboard
        isLoading: true,
        popupLoading: false,
        notOwner: true,
        hasChanges: false,
        nextUpdate: null,
        dontShow: false,
        showPrivateTooltip: false,
        historical: null,
        historicalData: {},
        cachedHistoricalData: {},
        chartDataExists: true,
        returns: {
            'All': 0.0,
            '2002 Crash': 0.0,
            '2002-2007': 0.0,
            '2008 Crash': 0.0,
            '2009-2014': 0.0,
            'COVID-19 Crash': 0.0,
        },

        // settings page
        settings: {
            'notifications': { 'enabled': true },
          'account': { 'email': null, 'plan': '', 'username': '' }
        },

        // search for assets-autocomplete
        query: '',
        suggestions: [],
        assetSearched: {},

        totalProfitLoss: 0,
        totalMarketValue: 0,
        totalFeesDollars: 0,
        totalProfitLossView: '',
        totalProfitLossViewPercent: '',
        totalProfitLossViewAmount: '',

        portfolio: {
            ver: 3,
            playGround: false,
            fees: 0,
            pName: '',
            pDescription: '',
            assets: [],
            privateMode: true,
            percent: 0,
            value: 0,
            showHealth: true,
            totalValue: 10000,
            autoSave: true,
            // settings
            // remember if we want to see $ or % in P&L
            formatGains: '$',
            notificationExists: false,
            // for comparison in the graph
            indexTicker: null
        },
        cards: {
            user: [],
            allAssets: [],
        },
        // list of historical data for community portfolios
        communityCharts: {},

        notices: [],
        visible: [],
        errorMessages: [],

        formatCurrencyInput(value) {
            if (value > 0) {
                return USD(value);
            }
            else {
                return "NA";
            }
        },

        add(notice) {
            notice.id = Date.now()
            this.notices.push(notice)
            this.fire(notice.id)
        },
        fire(id) {
            this.visible.push(this.notices.find(notice => notice.id == id))
            const timeShown = 2000 * this.visible.length
            setTimeout(() => {
                this.remove(id)
            }, timeShown)
        },
        remove(id) {
            const notice = this.visible.find(notice => notice.id == id)
            const index = this.visible.indexOf(notice)
            this.visible.splice(index, 1)
        },

        // Year Selection Input
        yearOptions: ['All', 'YTD', '1Y', '3Y'],
        yearSelected: 0, // Index of yearOptions
        yearRange: 'All',

        // Radio Selections
        radioOptions: [
            { value: 'portfolio', label: 'Portfolio' },
            { value: 'benchmark', label: 'Benchmark' }
        ],
        radioSelected: 'portfolio',

        // Dropdown Menu
        showDropdown: false,
        dropdownOptions: [
            { id: '1', label: 'S&P 500' },
            { id: '2', label: 'ETF Vanguard' },
            { id: '3', label: 'ETF Total' }
        ],
        dropdownSelected: { id: '1', label: 'S&P 500' },

        // Modal
        modal: {
            search: { 'visible': false, errorMessage: '' },
            newportfolio: false,
            login: false,
            quantity: false,
            upgrade: { 'visible': false, message: '' }
        },

        modalSearch: '',
        modalEditAsset: {},
        modalNewPortfolio: '',
        modalconfirmDeletePopup: null,

        popularAssets: [
            { name: 'SPDR S&P 500 exchange-traded fund ETF', ticker: 'SPY' },
            { name: 'crypto:Bitcoin', ticker: 'BTC' },
            { name: 'Vanguard Total Stock Market ETF', ticker: 'VTI' },
            { name: 'Invesco QQQ Trust', ticker: 'QQQ' },
            { name: 'Tesla, Inc', ticker: 'TSLA' },
            { name: 'Alphabet Inc', ticker: 'GOOG' },
            { name: 'VIDIA Corporation', ticker: 'NVDA' },
        ],

        // portfolios with these providers cannot be deleted
        permanent_providers: [
            'Plaid-US',
        ],

        // Mobile Pie Chart show/hide
        pieChartVisible: false,
        // -- dashboard end

        initializeMe() {
            return new Promise((resolve, reject) => {
                onAuthStateChanged(firebase_auth, (user) => {
                    this.loadingDone = false;
                    if (user) {
                        // User is signed in, and you can access user information.
                        // Handle user-specific logic here.
                        this.currentUser = user;
                        this.loadingDone = true;

                        // if not debug, set identify
                        if (typeof posthog !== 'undefined') {
                        posthog.identify(
                            user.uid,  // Replace 'distinct_id' with your user's unique identifier
                            { email: user.email, name: user.displayName } // optional: set additional person properties
                        );
                        }

                        this.getAchievementState();
                        resolve(user); // Resolve the promise after user is signed in
                    } else {
                        // User is not signed in.
                        // Handle the case where the user is not authenticated.
                        this.currentUser = null;
                        this.loadingDone = true;

                        this.clearAchievementState();

                        resolve(false);
                    }
                });
            });
        },

        getSlugFromURL() {
            const currentURL = window.location.href;

            // Split the URL by slashes and get the last part
            const urlParts = currentURL.split('/');
            const lastPart = urlParts[urlParts.length - 1];

            return lastPart;
        },

        async callAPIAuth(url, params, _return_resp_on_error = false) {
            const user = this.currentUser;
            if (user) {
                const requestData = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await user.getIdToken()}`
                    },
                    body: JSON.stringify(params)
                };

                try {
                    // Send the POST request
                    const response = await fetch(url, requestData);

                    if (response.ok || _return_resp_on_error) {
                        // Request was successful
                        const data = await response.json();
                        return data;
                    } else {
                        // Request failed
                        return { 'result': 'nok', 'data': 'API request failed' };
                    }
                } catch (error) {
                    if (_return_resp_on_error) return { 'result': 'nok', 'data': error.message };
                    return { 'result': 'nok', 'data': 'An error occurred during the API request' };
                }
            } else {
                return { 'result': 'nouser' }
            }
        },

        async callAPI(url) {
            try {
                const response = await fetch(url);
                if (response.ok) {
                    // Request was successful
                    const data = await response.json();
                    return data;
                } else {
                    // Request failed
                    return { 'result': 'nok', 'data': 'API request failed' };
                }
            } catch (error) {
                return { 'result': 'nok', 'data': 'An error occurred during the API request' };
            }
        },

        login() {
            this.authError = '';

            const email = this.email;
            const password = this.password;

            // validate email and password
            if (email == '' || password == '') {
                this.authError = 'Email and password are required';
                return;
            }

            this.isLoading = true;
            signInWithEmailAndPassword(firebase_auth, email, password)
                .then(async (userCredential) => {
                    // register the login and update the user object in db
                    await this.callAPIAuth('/v1/login', { 'currentUser': userCredential.user });

                    // Redirect to the previous URL                       
                    const url = localStorage.getItem('preLoginUrl') || '/dashboard';
                    localStorage.removeItem('preLoginUrl');
                    window.location.href = url;
                })
                .catch((error) => {
                    // Handle login error
                    this.authError = getFirebaseErrorMessage(error.message);
                });
            this.isLoading = false;
        },

        logout() {
            // Implement the logout logic using Firebase authentication
            firebase_auth.signOut().then(async function () {
                // Logout successful
                app.currentUser = null; // Clear the user 
                localStorage.removeItem('preLoginUrl');

                // clear user-specific data stored in TTLStorage
                await TTLStorage.remove('biggest_movers');

                window.location.href = '/';
            }).catch(function (error) {
                // Handle any logout errors
                console.error('Logout error: ', error);
            });
        },

        async register(referrer = undefined) {
            this.authError = '';

            const email = this.email;
            const password = this.password;

            // validate email and password
            if (email == '' || password == '') {
                this.authError = 'Email and password are required';
                return;
            }

            this.isLoading = true;
            await createUserWithEmailAndPassword(firebase_auth, email, password)
                .then(async (userCredential) => {
                    try {
                        // Explicitly sign in the user
                        await signInWithEmailAndPassword(firebase_auth, email, password);

                        // register in db
                        await this.callAPIAuth('/v1/register', {
                            'currentUser': userCredential.user,
                            'referrer': referrer,
                        });

                        // Redirect to the previous URL                        
                        const url = localStorage.getItem('preLoginUrl') || '/dashboard/first'
                        localStorage.removeItem('preLoginUrl');
                        window.location.href = url;

                    } catch (error) {
                        throw error;
                    }
                })
                .catch((error) => {
                    // Handle registration error                    
                    this.authError = getFirebaseErrorMessage(error.message);
                });
            this.isLoading = false;
        },

        async save() {
            this.isLoading = true;
            
            // store also the historicalData to create thumbnails faster 
            this.portfolio.allHistory = this.historicalData?.['All']?.values ? { ...this.historicalData['All'].values } : [];
            
            const result = await this.callAPIAuth(`/v1/save/portfolio/${this.getSlugFromURL()}`, { 'portfolio': this.portfolio, 'newPortfolioName': this.modalNewPortfolio });

            // portfolio copied
            if (result['message'] == 'showPopup') {
                // new wallet, get name
                this.modal.newportfolio = true;

                // copying a portfolio is achievement level 5
                this.updateAchievements(4);
            }
            // new portfolio
            else if (result['message'] == 'pcreated') {
                // new portfolio created
                window.location.href = '/p/' + result['slug']
                this.add({ type: 'success', text: '🔥 New portfolio created!' });

                // creating a new portfolio is achievement level 1
                console.log('new portfolio created');
                this.updateAchievements(1);
            }
            // updates
            else if (result['message'] == 'pupdated') {
                if (!this.portfolio.autoSave) {
                    this.add({ type: 'success', text: '🔥 Saved!' });
                }

                // instead of deleting and refetching the portfolio data, we also update the local data

                // recalculate market value
                this.portfolio.marketValue = 0;

                // parse assets in the required format
                const new_portfolio_assets = this.portfolio.assets.map((asset) => {
                    this.portfolio.marketValue += asset.latestPrice * asset.quantity;
                    
                    return {
                        name: asset.name,
                        ticker: asset.ticker,
                        price: asset.latestPrice,
                        quantity: asset.quantity,
                        performance: (asset.marketValue || 0) - (asset.PoA || 0) * asset.quantity,
                        priceChange: ((asset.latestPrice || 0) - (asset.PoA || 0)) / (asset.PoA || 1) * 100,
                        priceChange2D: 0, // needs fixin'
                    }
                });               
            }

            // user not logged in            
            else if (result['result'] === 'nouser') {
                this.modal.login = true;
            }
            else {
                this.add({ type: 'error', text: result.data || 'An error occurred while saving. Please try again.' });
            }

            this.updateHasChanges(false);
            this.isLoading = false;

        },

        // update portfolio settings
        async update(slug, settings) {
            const result = await this.callAPIAuth(`/v1/update/portfoliosettings/${slug}`, { settings });
            if (result['message'] == 'pupdated') {
                this.add({ type: 'success', text: '🔥 Updated!' });
            }
            else {
                this.modal.login = true;
                //this.add({ type: 'error', text: 'Not logged in' });
            }
        },

        async updatePortfolioName(slug, name) {           
            // update portfolio db instance
            await this.callAPIAuth(`/v1/update/portfolioname/${slug}`, { 'newPortfolioName': name });
            this.add({ type: 'success', text: '🔥 Updated!' });
        },

        async fetchNetWorthHistoryData() {
            let data = [];

            const result = await this.callAPIAuth(`/v1/get/netWorthHistory`);

            if (result['netWorthHistory']) {
                // Sort the data by year and week number in descending order
                data = result['netWorthHistory'].sort((b, a) => (a.year - b.year) || (a.week - b.week));

                // Track the first week of each month using a Set
                const processedMonths = new Set();

                // Filter to keep only the last week of each month
                const filteredData = data.filter((item) => {
                    const month = new Date(item.year, 0, (item.week - 1) * 7 + 1).getMonth();
                    const monthYearKey = `${item.year}-${month}`;

                    // If this month-year pair has not been processed, add it to the Set and include it in the filtered data
                    if (!processedMonths.has(monthYearKey)) {
                        processedMonths.add(monthYearKey);
                        return true;
                    }

                    return false;
                }).map((item) => {
                    const month = new Date(item.year, 0, (item.week - 1) * 7 + 1).getMonth();
                    const monthName = getMonthName(month);

                    return {
                        month: monthName,
                        year: item.year,
                        netWorth: item.data.networth,
                    };
                });

                // reverse the data to show the latest first
                filteredData.reverse();

                return filteredData;
            }

            return data;  // Empty array if no data exists
        },

        pieChartWorthInstance: null,
        barChartWorthInstance: null,
        async getNetWorthChartData() {

            // colors
            const pieColors = [
                // shades of blue
                '#003D9F',
                '#5283D2',
                '#A3BDE7',
                // shades of gray ;)
                '#6C737F',
                '#9DA4AE',
            ];

            // --- Fetch & Preprocess the data ---

            // get data for the portfolio pie chart
            const pieData = this.cards['user'].map((l) => l.marketValue);
            const pieDataLabels = this.cards['user'].map((l) => l.name);

            // get data for the portfolio bar chart
            const barResp = await this.fetchNetWorthHistoryData();
            // remove the last week and networth (which is the current one)
            const barData = barResp.map((item) => item.netWorth).slice(0, -1);
            const barLabels = barResp.map((item) => `${item.month} ${item.year}`).slice(0, -1);

            // add the last week and networth to the bar data
            barLabels.push('Now');
            barData.push(this.netWorth);

            // --- Render the charts ---

            // set up the two net worth graphs
            const pieChart = document.getElementById('net-worth-pie-chart');

            const pieChartConfig = {
                type: 'pie',
                data: {
                    labels: pieDataLabels,
                    datasets: [{
                        data: pieData,
                        backgroundColor: pieColors,
                        borderWidth: 0
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            };     

            const barChart = document.getElementById('net-worth-bar-chart');
            const barChartConfig = {
                type: 'bar',
                data: {
                    labels: barLabels,
                    datasets: [
                        {
                            label: 'Net Worth',
                            data: barData,
                            backgroundColor: pieColors[0],
                            borderWidth: 0,
                            borderRadius: 10,
                        },
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            // display: false // Hides the x-axis
                        },
                        y: {
                            stacked: true,
                            display: false // Hides the y-axis
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            };

            if (this.barChartWorthInstance) this.barChartWorthInstance.destroy();
            this.barChartWorthInstance = new Chart(barChart, barChartConfig);
        },

        async deletePortfolio(slug) {
            this.modalconfirmDeletePopup = null;
            const result = await this.callAPIAuth(`/v1/delete/portfolio/${slug}`);
            if (result['message'] == 'pdeleted') {
                this.add({ type: 'success', text: '🔥 Deleted!' });
                
                // find portfolio from the local state and remove it
                this.cards['user'] = this.cards['user'].filter(portfolio => portfolio.slug !== slug);
            }
            else {
                this.modal.login = true;
                //this.add({ type: 'error', text: 'Not logged in' });
            }
        },

        async getPortfolio(slug = null, update_prices = false) {
            this.isLoading = true;
            if (!slug) slug = this.getSlugFromURL();

            let responseData;
            let urlForData;
            // if we need to update the portfolio prices, use this url
            if (update_prices) {
                urlForData = `/v1/get/portfolio/${slug}`;
            }
            else {
                urlForData = `/cachable/get/portfolio/${slug}`;
            }

            // call the url 
            if (this.currentUser) {
                responseData = await this.callAPIAuth(urlForData, {});
            }
            else {
                responseData = await this.callAPI(urlForData, {});
            }

            const { data: portfolioData, has_updates: has_updates, state: { notOwner, isAdmin } } = responseData;
            // if the portfolio is private and you are not the owner
            if (portfolioData == null) {
                this.dontShow = true;
                return;
            }

            // check if the viewer is the owner of the portfolio
            this.notOwner = notOwner || false;
            this.portfolio = { ...portfolioData };
            this.isLoading = false;
            this.isAdmin = isAdmin;

            // update historicalData
            for (const asset of this.portfolio.assets) {
                if (asset['latestPrice']) {
                    this.cachedHistoricalData[asset['ticker']] = { ...asset['historical_data'] };
                }
            }
            this.updateNextUpdate(portfolioData.lastRefreshed);

            // update colors of the portfolio
            this.updateColors()
            this.updatePortfolioView();

            this.updateHasChanges(false);

            // new data available, refresh
            if (has_updates) {
                this.getPortfolio(slug, has_updates);
            }
        },

        // NOT USED ANYMORE
        async updatePortfolioPrices() {
            const assetPromises = this.portfolio.assets.map(async (asset) => {
                try {
                    const ticker = asset['ticker'];
                    const data = await this.fetchData(ticker);

                    // create also the initial percentages
                    asset.initialPercent = asset.value / this.portfolio.totalValue * 100;

                    return { ...asset, fee: data['fee'], name: data['name'], latestPrice: data['latestPrice'], assetClass: data['assetClass'] };
                } catch (error) {
                    console.error(`Failed to fetch data for ticker: ${asset['ticker']} `, error);
                }
            });
            const updatedAssets = await Promise.all(assetPromises);
            this.portfolio.assets = updatedAssets;
        },

        /**
         * Used as a callback function to update portfolios
         */
        async _fetchPortfolios() {
            const portfolioData = await this.callAPIAuth('/v1/get/portfolios-and-assets');

            // 'result' will only exist if there is an error
            if (portfolioData['result']) return [];

            const chartData = Object.values(portfolioData);
            return chartData.map((l) => ({
                'name': l.pname,
                'slug': l.slug,
                'marketValue': l.marketValue,
                'playGround': l.playGround,
                'privateMode': l.privateMode,
                'allHistory': l.historicalData,
                'created_at': l.created_at,
                'gradient': this.getRandomGradient(),
                'assets': l.assets,
                'provider': l.provider || null,
            }))
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sorting by created_at
        },
       
        async getPortfolios() {
            const portfolioData = await this._fetchPortfolios();

            if (!portfolioData.length) {
                this.cards['user'] = [];
                this.cards['allAssets'] = [];
                return;
            }

            this.cards['user'] = portfolioData;
            this.cards['allAssets'] = portfolioData.reduce((acc, curr) => acc.concat(
                curr.assets.map(asset => ({
                    ...asset,
                    portfolioName: curr.name,
                    portfolioSlug: curr.slug,
                    portfolioPlayground: curr.playGround,
                }))
            ), []);
        },

        /**
         * Sorts assets in this.cards['allAssets'] based on the mode.
         * 
         * @param {string} mode - Mode to sort assets by. Possible values: 'portfolio', 'name', 'quantity', 'price', 'performance'
         * @param {boolean} ascending - Whether to sort in ascending order. Default is true.
         */
        sortAssets(mode = 'portfolio', ascending = true) {
            const sortFunction = ascending ? assetSortFunctions[mode] : (a, b) => assetSortFunctions[mode](b, a);
            this.cards['allAssets'] = this.cards['allAssets'].sort(sortFunction);
        },

        async getCommunityPortfolios() {
            const portfolioData = await this.callAPI('/v1/get/communityportfolios');

            // Iterate over each category of portfolioData
            Object.keys(portfolioData).forEach(categoryKey => {
                // Ensure the category is an array
                if (Array.isArray(portfolioData[categoryKey])) {
                    // Process each portfolio in the category
                    this.cards[categoryKey] = portfolioData[categoryKey].filter(portfolio => portfolio !== null).map(l => ({
                        'name': l.pName,
                        'slug': l.slug,
                        'marketValue': l.totalValue,
                        'growth': l.growth,
                        'description': l.pDescription,
                        'allHistory': l.allHistory,
                        'gradient': this.getRandomGradient()
                    }));
                } else {
                    console.error(`Expected array but got ${typeof portfolioData[categoryKey]} for category ${categoryKey}`);
                }
            });
        },

        updateNextUpdate(newTime) {
            const lastRefreshedDate = new Date(newTime + 'Z'); // Parse the date string, it is in UTC
            const currentDate = new Date(); // Get the current date and time in UTC
            const oneHourInMilliseconds = 3600000; // 1 hour in milliseconds

            // Calculate the time difference in milliseconds
            const timeElapsed = currentDate - lastRefreshedDate;

            // Calculate the time left until one hour
            const timeLeft = oneHourInMilliseconds - timeElapsed;

            // Convert the time left to minutes
            const timeLeftInMinutes = timeLeft / (1000 * 60);

            // If the time left is less than 0, it means more than an hour has passed
            this.nextUpdate = timeLeftInMinutes > 0 ? Math.round(timeLeftInMinutes) : 0;
        },

        getSamplePortfolios() {
            const portfolioData = [{ 'pname': "Bogle's Three Fund", 'slug': 'simple-starter_YF73bfHsZ4', 'assets': [50, 20, 30] },
            { 'pname': 'Tech stocks', 'slug': 'tech-stocks_lT73jZlvvi', 'assets': [10, 10, 30, 40, 20] },
            { 'pname': 'Dividends pay your bills', 'slug': 'pays-my-bills_O5RHBiiarj', 'assets': [10, 10, 10, 10, 10, 30, 20] }
            ]
            const chartData = Object.values(portfolioData);
            this.cards = chartData.map((l) => [l.pname, l.slug, l.assets, this.getRandomGradient()]);
        },

        setupCharts() {
            Array.from(document.querySelectorAll("#chart")).forEach((chart, idx) => {
                new Chart(chart, {
                    type: "pie",
                    data: {
                        datasets: [
                            {
                                data: this.cards[idx][2],
                                backgroundColor: this.getRandomColors(5),
                                borderWidth: 0,
                                labels: this.cards
                            },
                        ],
                    },
                    options: {
                        tooltips: {
                            enabled: false,
                        },
                        hover: {
                            mode: null,
                        },
                        rotation: Math.random() * 10 * Math.PI - (25 / 180) * Math.PI,
                        plugins: {
                            labels: false,
                        },
                    },
                });
            });
        },

        getRandomGradient() {
            return `/static/imgs/gradients/gradient_${1 + Math.floor(Math.random() * 11)}.png`
        },

        getRandomColors(n) {
            const colors = [
                "#0067ff",
                "#ffad00",
                "#52c7c1",
                "#ff1164",
                "#d649ff",
                "#48f295",
                "#ff7f00",
                "#7e3ed3",
                "#edf220",
            ];
            return colors.sort(() => 0.5 - Math.random()).slice(0, n);
        },

        renderCards(_cards = undefined) {
            const cards = _cards || this.cards;

            this.isLoading = true;
            let allSecondLevelKeys = new Set();  // Use a Set to avoid duplicate keys

            // Iterate over each category of cards
            Object.keys(cards).forEach((categoryKey) => {
                // Iterate over each card in the category
                if (!cards[categoryKey].length) return;

                cards[categoryKey].forEach((card) => {
                    // Collect all keys from each card object
                    Object.keys(card).forEach(key => {
                        allSecondLevelKeys.add(key);
                    });

                    // Example processing
                    const id = `chart-${card.slug}`;
                    if (card.allHistory) {
                        this.communityLineChart(id, card.allHistory);
                    }
                });
            });
            this.isLoading = false;
        },

        async fetchData(ticker = null) {
            var cached = false;
            if (ticker in this.cachedHistoricalData) {
                cached = true;
            }
            try {
                const response = await fetch(`/v1/get/${ticker}?cached = ${cached}`);
                const data = await response.json();

                if (data['latestPrice']) {
                    if (!cached) {
                        this.cachedHistoricalData[data['ticker']] = { ...data['historical_data'] };
                    }
                    // Use the data here or return it if needed
                    return data;
                } else {
                    // Handle the case where 'latestPrice' is not available
                }
            } catch (error) {
                this.ajax_form_message = 'Ooops! Something went wrong!';
                this.isLoading = false;
                // Handle fetch error
                console.error(error);
            }
        },

        async addAssetFromSearch(closeModal = false) {
            if (this.dataIsMissing(this.assetSearched)) {
                return;
            }

            if (closeModal) {
                this.modal.search.visible = false
            }

            this.isLoading = true;
            this.assetSearched.value = this.assetSearched.quantity * this.assetSearched.PoA;
            this.portfolio.assets.push(this.assetSearched);

            this.clickQuantity();
            this.updateColors();

            this.suggestions = [];
            this.isLoading = false;

            this.add({ type: 'success', text: `${this.assetSearched.ticker} added!` });

            this.assetSearched = {};
            this.modal.search.errorMessage = '';

            this.updateHasChanges(true);

            // adding an asset is achievement level 2
            this.updateAchievements(2);
        },

        dataIsMissing(data) {
            // check if we have all data            
            if ((!isFinite(parseFloat(data.quantity))) || (data.quantity == undefined) || (data.quantity == null) || parseInt(data.quantity) == 0) {
                this.modal.search.errorMessage = 'Quantity missing';
                this.modal.search.visible = true;
                return true;
            }
            else if ((data.DoA == '') || (data.DoA == undefined) || (data.DoA == null)) {
                this.modal.search.errorMessage = 'Purchase date missing';
                this.modal.search.visible = true;
                return true;
            }
            else if ((!isFinite(parseFloat(data.PoA))) || (parseFloat(data.PoA) <= 0) || (data.PoA == undefined) || (data.PoA == null)) {
                this.modal.search.errorMessage = 'Purchase price missing';
                this.modal.search.visible = true;
                return true;
            }
            else {
                return false;
            }
        },

        startFlashing(element) {
            let intervalId = setInterval(() => {
                element.classList.toggle('bg-yellow-100');
            }, 200);
            setTimeout(() => {
                clearInterval(intervalId);
                element.classList.remove('bg-yellow-100'); // Remove class after flashing stops
            }, 2000);
        },

        removeAsset(idx) {
            this.add({ type: 'info', text: `${this.portfolio.assets[idx].ticker} removed!` });
            this.portfolio.assets.splice(idx, 1);
            this.updatePortfolioView();

            this.updateHasChanges(true);
        },

        updatePortfolioView() {
            const totalValue = this.portfolio.assets.reduce((totalValue, asset) => totalValue + (asset.value || 0), 0);
            this.portfolio.totalValue = parseFloat(totalValue % 1 === 0 ? totalValue.toFixed(0) : totalValue.toFixed(2));

            this.portfolio.assets.forEach((item, index) => {
                if (!isNaN(item.PoA)) {
                    item.marketValue = item.quantity * item.latestPrice;
                    item.profitLoss = (item.marketValue - item.value);

                    if (this.portfolio.formatGains != '%') {
                        item.profitLossView = currency(item.profitLoss, { symbol: '$', precision: 2 }).format();
                    }
                    else {
                        const temp = item.profitLoss / item.value * 100
                        item.profitLossView = parseFloat(temp % 1 === 0 ? temp.toFixed(0) : temp.toFixed(2)) + "%"
                    }
                }
            });

            this.totalMarketValue = this.portfolio.assets.reduce((totalMarketValue, asset) => totalMarketValue + (asset.marketValue || 0), 0);

            this.portfolio.assets.forEach((item, index) => {
                if (!isNaN(item.value)) {
                    const calculatedPercent = item.marketValue / this.totalMarketValue * 100;
                    item.percent = parseFloat(calculatedPercent % 1 === 0 ? calculatedPercent.toFixed(0) : calculatedPercent.toFixed(2));
                    item.initialPercent = item.value / this.portfolio.totalValue * 100;
                }
            });

            this.portfolio.fees = (this.portfolio.assets.reduce((fee, asset) => {
                const feeValue = (asset.fee ? parseFloat(asset.fee.replace('%', '')) || 0 : 0) * asset.percent / 100;
                return fee + feeValue;
            }, 0)).toFixed(4);

            this._updateGains();

            if (parseFloat(this.portfolio.totalValue) > 0) {
                this._updateStats();
            }

            this.totalFeesDollars = ((this.portfolio.fees * this.totalMarketValue) / 100).toFixed(2);
        },

        _updateGains() {
            this.totalProfitLoss = this.portfolio.assets.reduce((totalProfitLoss, asset) => totalProfitLoss + (asset.profitLoss || 0), 0);
            this.totalProfitLoss = parseFloat(this.totalProfitLoss % 1 === 0 ? this.totalProfitLoss.toFixed(0) : this.totalProfitLoss.toFixed(2));

            this.totalProfitLossViewAmount = currency(this.totalProfitLoss, { symbol: '$', precision: 2 }).format();

            const temp = (this.totalProfitLoss / this.portfolio.totalValue) * 100
            this.totalProfitLossViewPercent = parseFloat(temp % 1 === 0 ? temp.toFixed(0) : temp.toFixed(2)) + "%";

            if (this.portfolio.formatGains != '%') {
                this.totalProfitLossView = this.totalProfitLossViewAmount;
            }
            else {
                const temp = (this.totalProfitLoss / this.portfolio.totalValue) * 100
                this.totalProfitLossView = this.totalProfitLossViewPercent;
            }
        },

        changeInitialValue() {
            this.portfolio.assets.forEach((item, index) => {
                item.value = this.portfolio.totalValue * (item.initialPercent / 100);

                if (item.PoA) {
                    let quantityFloat = item.value / item.PoA;
                    item.quantity = Math.round(quantityFloat);
                    // Adjust item.PoA based on the rounded quantity
                    item.PoA = item.value / item.quantity;
                } else {
                    let quantityFloat = item.value / item.latestPrice;
                    item.quantity = Math.round(quantityFloat);
                    // Adjust item.latestPrice based on the rounded quantity
                    item.latestPrice = item.value / item.quantity;
                }
            });
            this.updatePortfolioView();
            this.updateHasChanges(true);
        },

        clickQuantity() {
            this.portfolio.assets.forEach((item, index) => {
                if (isNaN(item.quantity)) {
                    item.quantity = 0;
                } else {
                    item.quantity = item.quantity;
                }
                if (item.PoA) {
                    item.value = item.quantity * item.PoA;
                }
            });
            this.updatePortfolioView();
            this.updateHasChanges(true);
        },

        editAsset(index, update = false) {
            if (update) {
                this.modal.quantity = false;
                this.portfolio.assets[index] = { ... this.modalEditAsset };
                this.modalEditAsset = {};

                this.clickQuantity();
            }
            else {
                this.modal.quantity = true;
                this.modalEditAsset = { ... this.portfolio.assets[index] };
                this.modalEditAsset.index = index;
            }
        },

        pieChartBig(id, data = None) {
            if (pieChart) pieChart.destroy();

            const tm_data = data || this.portfolio.assets.map((l) => [l.percent]);

            const chart = document.getElementById(id);

            pieChart = new Chart(chart, {
                type: "pie",
                data: {
                    datasets: [
                        {
                            data: tm_data,
                            backgroundColor: ['blue',
                                'orange',
                                'cyan',
                                'magenta',
                                'red'],
                            borderWidth: 0,
                        },
                    ],
                },
                options: {
                    tooltips: {
                        enabled: false,
                    },
                    hover: {
                        mode: null,
                    },
                    plugins: {
                        labels: {
                            render: "percentage",
                            position: "outside",
                            segment: true,
                            textMargin: 10,
                            outsidePadding: 20,
                        },
                    },
                },
            });

        },

        async lineChart(index = null) {
            if (chartBig) chartBig.destroy();
            // filter based on the bar
            this.yearRange = this.yearOptions[this.yearSelected];

            // not enough data for this period
            if (Object(this.historicalData[this.yearRange].dates).length < 2) {
                this.chartDataExists = false;
            }
            else {
                this.chartDataExists = true;
            }
            const configuration = {
                type: "line",
                data: {
                    labels: this.historicalData[this.yearRange].dates,
                    datasets: [
                        {
                            label: this.portfolio.pName,
                            borderColor: "rgba(0, 73, 189, 1)",
                            backgroundColor: "rgba(0, 73, 189, 0.2)",
                            borderWidth: 1.5,
                            fill: true,
                            lineTension: 0.1,
                            data: this.historicalData[this.yearRange].values,
                            pointRadius: 0,
                            error: [0.5],
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top'
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        intersect: false
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Month'
                            }
                        },
                        y: {
                            grid: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: 'Value ($)'
                            }
                        }
                    }
                },
            };

            // if index is not null then add another dataset to the chart
            if (index !== null) {
                let comparedData = {};
                const indexData = await this.fetchData(this.portfolio.indexTicker);

                const today = new Date().toISOString().split('T')[0];
                const filter_bar_dates = this._create_dates(today);

                const interesting_dates = {
                    'YTD': [filter_bar_dates[0], today],
                    '1Y': [filter_bar_dates[1], today],
                    '3Y': [filter_bar_dates[2], today],
                    //'5Y': [filter_bar_dates[3], today],
                    'All': [filter_bar_dates[4], today]
                }

                const amountForThisPeriod = this.historicalData[this.yearRange].values[0];
                const startDate = this.historicalData[this.yearRange].dates[0];
                const endDate = this.historicalData[this.yearRange].dates[this.historicalData[this.yearRange].dates.length - 1];

                const formattedData = this._asset_to_timeframe_data(startDate, endDate, this.yearRange, indexData, 1);

                const temp_quantity = amountForThisPeriod / formattedData.values[0];

                formattedData.values = formattedData.values.map(x => x * temp_quantity);
                comparedData[this.yearRange] = { ...formattedData };

                configuration.data.datasets.push({
                    label: this.portfolio.indexTicker,
                    borderColor: "rgba(255, 0, 0, 1)",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                    borderWidth: 1.5,
                    fill: true,
                    lineTension: 0.1,
                    data: comparedData[this.yearRange].values,
                    pointRadius: 0,
                    error: [0.5],
                });

            }

            let ctx = document.getElementById('lineChartSide');
            ctx.width = "100%";
            ctx.height = "100%";

            if (ctx) {
                chartBig = new Chart(ctx, configuration);
            }

        },

        communityLineChart(id, historical_data) {
            const zeros = Array(Object(historical_data).length).fill(40);
            if (!Object.keys(historical_data).length) return;  // Early exit if data is empty

            const dataArray = Object.keys(historical_data).map(key => historical_data[key]);
            let noName = dataArray.map((_, i) => i + 1);

            const configuration = {
                type: "line",
                data: {
                    labels: noName,
                    datasets: [
                        {
                            borderColor: "rgba(255, 255, 255, .75)",
                            backgroundColor: "rgba(0,0,0,0)",
                            borderWidth: 2.5,
                            fill: true,
                            lineTension: 0.1,
                            data: dataArray,
                            pointRadius: 0
                        },
                        {
                            borderColor: "rgba(255, 255, 255, .75)",
                            backgroundColor: "rgba(0,0,0,0)",
                            borderWidth: 2.5,
                            borderDash: [5, 5],
                            fill: true,
                            lineTension: 0.1,
                            data: zeros,
                            pointRadius: 0,
                            error: [0.5],
                        }
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        intersect: false
                    },
                    scales: {
                        y: {
                            display: false,
                            ticks: {
                                display: false,
                            },
                        },
                        x: {
                            display: false,
                            ticks: {
                                display: false,
                            },
                        }
                    },
                },
            };

            const contexts = document.querySelectorAll(`canvas[id = '${id}']`);
            contexts.forEach((ctx) => {
                if (!ctx) return;
                ctx.width = ctx.offsetWidth;
                ctx.height = ctx.offsetHeight;
                new Chart(ctx, configuration);
            });
        },

        async initDatePicker(id) {
            const datePickerInput = document.getElementById(id);

            let options = {
                locale: en,
                dateFormat: 'MMM dd, yyyy',
                startDate: new Date(),
                autoClose: true,
                onSelect: (formattedDate) => {
                    // store the date, get prices for the asset to get the price of the day
                    this.handleDateSelection(id, formattedDate); // Call the async function
                }
            }
            // Initialize air-datepicker
            new AirDatepicker(datePickerInput, options);
        },

        // Define the async function to handle the date selection logic
        async handleDateSelection(id, formattedDate) {
            if (id == 'datepicker-assetSearched') {
                // load the prices of the searched ticket
                const data = await this.fetchData(this.assetSearched.ticker);
                this.assetSearched.fee = data['fee'];
                this.assetSearched.latestPrice = data['latestPrice'];
                this.assetSearched.historical_data = data['historical_data']

                let priceMonth = 0;
                // we have to find the price of the month
                const strDate = convertDateToYMD(formattedDate['date']);
                for (const key in this.cachedHistoricalData[this.assetSearched.ticker]) {
                    if (key.startsWith(strDate.substring(0, 7))) {
                        priceMonth = this.cachedHistoricalData[this.assetSearched.ticker][key];
                    }
                }
                // if we couldn't find the price due to caching, just use the latest price
                if (priceMonth == 0) {
                    priceMonth = this.assetSearched.latestPrice;
                }

                this.assetSearched.PoA = priceMonth;
                this.assetSearched.DoA = formattedDate['formattedDate'];
            }
            else {
                this.modalEditAsset.DoA = formattedDate['formattedDate'];
            }
        },

        lineChartSmall(id, key) {
            const ctx = document.getElementById(id);
            ctx.width = "100%";
            ctx.height = "100%";

            smallChart && smallChart.destroy();
            smallChart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: this.historicalData[key].dates,
                    datasets: [
                        {
                            //borderColor: isIncreasing ? "#1CCE6D" : "#FF5B5B",
                            borderColor: "#FF5B5B",
                            borderWidth: 1,
                            fill: false,
                            lineTension: 0,
                            data: this.historicalData[key].values,
                            pointRadius: 0,
                            error: [0.5],
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        intersect: false
                    },
                    scales: {
                        x: {
                            display: false // Hides the x-axis
                        },
                        y: {
                            display: false // Hides the y-axis
                        }
                    }
                },

            });
        },

        async fetchSuggestions() {
            if (Object(this.modalSearch).length > 1) {
                // Make an HTTP request to your Flask backend
                const data = await this.callAPI(`/v1/autocomplete?query=${this.modalSearch}`);

                this.suggestions = { ...data.suggestions };
            }
        },

        _asset_to_timeframe_data(fromDate, toDate, periodName, asset, quantity = 1000) {
            const convertedFromDate = convertMonthYearToYMD(fromDate);
            // get all the dates that are needed and we have data
            const keys = Object.keys(asset.historical_data).filter(date => {
                return date >= convertedFromDate;
            });

            let filteredData = {};
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                // convert keys to this format "month-year"
                const monthYear = formatDateForGraph(key);
                filteredData[monthYear] = asset.historical_data[key] * quantity;
            }

            // Override this month's value with the current price
            const currentMonth = formatDateForGraph(new Date());
            filteredData[currentMonth] = asset.latestPrice * quantity;

            return {
                dates: Object.keys(filteredData),
                values: Object.values(filteredData)
            };
        },

        _create_timeframe_data(fromDate, toDate, periodName, asset = null) {
            const convertedFromDate = convertDateToYMD(fromDate);
            let mergedResult = [];
            for (const asset of this.portfolio.assets) {
                const ticker = asset['ticker'];
                const quantity = asset['quantity'];

                // let's make sure that the asset has a Date of Acquisition
                if (asset.DoA) {
                    // warning that we don't have data for all the time
                    if (periodName != 'All' && fromDate < new Date(Object.keys(this.cachedHistoricalData[ticker])[0])) {
                        console.log('Not enough data for asset ' + ticker + ' for period ' + fromDate);
                    }

                    // get all the dates that are needed and we have data
                    const keys = Object.keys(this.cachedHistoricalData[ticker]).filter(date => {
                        const convertedAssetDate = convertDateToYMD(asset.DoA);
                        const latestDate = convertedFromDate > convertedAssetDate ? convertedFromDate : convertedAssetDate;
                        return date >= latestDate;
                    });

                    let filteredData = {};
                    for (let i = 0; i < keys.length; i++) {
                        let key = keys[i];
                        // convert keys to this format "month-year"
                        const monthYear = formatDateForGraph(key);
                        filteredData[monthYear] = this.cachedHistoricalData[ticker][key] * quantity;
                    }

                    // Override this month's value with the current price
                    const currentMonth = formatDateForGraph(new Date());
                    filteredData[currentMonth] = asset.latestPrice * quantity;
                    mergedResult = { ...this._addDatesAndPerformance(mergedResult, filteredData) };
                }
            }

            // timeseries                        
            this.historicalData[periodName] = {}
            this.historicalData[periodName].dates = Object.keys(mergedResult)
            this.historicalData[periodName].values = Object.values(mergedResult)

            // calculate returns
            // Get the keys (dates) of the object
            const lkeys = Object.keys(mergedResult);
            // Get the first and last keys (dates)
            const lfirstDate = lkeys[0];
            this._updateProfitLossBar(periodName, mergedResult[lfirstDate]);
        },

        _updateProfitLossBar(periodName, initialValue) {
            this.returns[periodName] = {};

            // for updating returns, we use the last value from the portfolio as of today
            // because historical data don't update daily
            this.returns[periodName]['value'] = this.totalMarketValue;

            if (periodName == 'All') {
                this.returns[periodName]['profitLoss'] = this.totalProfitLoss;
                this.returns[periodName]['profitLossPercent'] = this.totalProfitLossViewPercent;
            }
            else {
                this.returns[periodName]['profitLoss'] = this.returns[periodName]['value'] - initialValue;
                const temp = this.returns[periodName]['profitLoss'] / initialValue * 100;
                this.returns[periodName]['profitLossPercent'] = parseFloat(temp % 1 === 0 ? temp.toFixed(0) : temp.toFixed(2)) + "%";
            }

            this.returns[periodName]['profitLossView'] = currency(this.returns[periodName]['profitLoss'], { symbol: '$', precision: 2 }).format();
        },

        _create_dates() {
            const today = new Date();

            today.setFullYear(today.getFullYear(), 0, 1);
            const zeroYear = today.toISOString().split('T')[0];

            today.setFullYear(today.getFullYear() - 1, 0, 1);
            const oneYear = today.toISOString().split('T')[0];

            today.setFullYear(today.getFullYear() - 2, 0, 1);
            const threeYear = today.toISOString().split('T')[0];

            today.setFullYear(today.getFullYear() - 2, 0, 1);
            const fiveYear = today.toISOString().split('T')[0];

            // find the earliest of DoA and start from there
            const actualDate = this._findEarliestDate(this.portfolio.assets.map((asset) => asset.DoA));

            return [zeroYear, oneYear, threeYear, fiveYear, actualDate];
        },

        _findEarliestDate(dateArray) {
            // Map the date strings to Date objects
            const dateObjects = dateArray.map(convertMonthDayYearToDate);

            // Filter out invalid dates
            const validDates = dateObjects.filter(date => !isNaN(date.getTime()));

            // Find the earliest Date object
            const earliestDate = new Date(Math.min.apply(null, validDates));

            // Convert it to the format we want
            return convertDateToYMD(earliestDate);
        },

        async _updateStats() {
            this.historicalData = {};
            this.returns = {};

            const today = new Date().toISOString().split('T')[0];
            const filter_bar_dates = this._create_dates(today);

            const interesting_dates = {
                'YTD': [filter_bar_dates[0], today],
                '1Y': [filter_bar_dates[1], today],
                '3Y': [filter_bar_dates[2], today],
                //'5Y': [filter_bar_dates[3], today],
                'All': [filter_bar_dates[4], today],
                //'2002 Crash': ['2002-01-01', '2002-12-31'],
                // '2002-2007': ['2002-12-31', '2007-12-31'],
                // '2008 Crash': ['2008-01-01', '2008-12-31'],
                // '2009-2014': ['2009-01-01', '2014-12-31'],
                // '2020 Crash': ['2019-01-01', '2020-12-31'],
                // 'COVID-19 Crash': ['2020-01-01', '2020-03-25']
            }

            for (const indate of Object.keys(interesting_dates)) {
                var from_date = interesting_dates[indate][0];
                const to_date = interesting_dates[indate][1];

                this._create_timeframe_data(new Date(from_date), new Date(to_date), indate)
            }

            // render Graph
            this.lineChart(this.portfolio.indexTicker);
        },

        updateColors() {
            const colors = [
                "#0067ff",
                "#ffad00",
                "#52c7c1",
                "#ff1164",
                "#d649ff",
                "#48f295",
                "#ff7f00",
                "#7e3ed3",
                "#edf220",
            ];
            var colorIdx = 0;
            this.portfolio.assets.forEach((item, index) => {
                if (item) {
                    item.color = colors.slice(colorIdx, colorIdx + 1)[0];
                    colorIdx += 1;
                }
            });
        },

        _addDatesAndPerformance(obj1, obj2) {
            const mergedObject = { ...obj1 };

            for (const key in obj2) {
                if (mergedObject.hasOwnProperty(key)) {
                    mergedObject[key] += obj2[key];
                }
                else {
                    mergedObject[key] = obj2[key];
                }
            }

            // the dates might not be sorted so let's sort
            const sortedEntries = Object.entries(mergedObject).sort((a, b) => {
                // Convert keys to date objects for comparison
                const dateA = convertMonthYearToDate(a[0]);
                const dateB = convertMonthYearToDate(b[0]);
                return dateA - dateB; // Sorts in ascending order
            });

            // Convert sorted array back to object
            const sortedObject = {};
            for (const [key, value] of sortedEntries) {
                sortedObject[key] = value;
            }

            return sortedObject;
        },

        // Uploading and processing portfolios
        handleDragOver(event) {
            event.dataTransfer.dropEffect = 'copy';
        },

        async handleDrop(event) {
            const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            if (files.length > 0) {
                const file = files[0];
                this.uploadFile(file);
            }
        },

        uploadFile(file) {
            this.fileForDebugging = null;

            // Simulate upload progress
            this.uploadProgressVisible = true;
            let uploadInterval = setInterval(() => {
                if (this.uploadProgress < 100) {
                    this.uploadProgress += 10; // Increment progress
                } else {
                    clearInterval(uploadInterval);
                    this.uploadProgressVisible = false;
                    this.previewImage(file);
                    // this.processImage(file);
                    this.processImage_v2(file);
                }
            }, 100);
        },

        previewImage(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result;
                this.fileForDebugging = e.target.result; // in case the user wants to send it for debugging
            };
            reader.readAsDataURL(file);
        },

        async processImage(file) {
            this.processingProgressVisible = true;

            try {
                // Dynamically import Tesseract
                const Tesseract = (await import('tesseract.js')).default;

                // Process the image
                const result = await Tesseract.recognize(
                    file,
                    'eng',
                    {
                        logger: m => {
                            if (m.status === 'recognizing text') {
                                this.processingProgress = parseInt(m.progress * 100);
                            }
                        }
                    }
                );

                // Handle the result
                const responseData = await this.callAPIAuth('/v1/import/portfolio/screenshot', { 'fulltext': result.data.text });
                this.portfolio = { ...responseData['message'] };
                this.portfolio.imported = 'screenshot';
                this.errorMessages = [...responseData['errors']];

                this.updateHasChanges(true);

                // scroll to the results area
                document.getElementById('results').scrollIntoView({ behavior: 'smooth' });
                this.clickQuantity();

            } catch (error) {
                console.error('Error processing image:', error);
                // Handle the error
            } finally {
                this.processingProgressVisible = false;
                console.log('Processing complete');
            }
        },

        async processImage_v2(file) {
            const user = this.currentUser

            const formData = new FormData();
            formData.append("image", file);

            const requestData = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${await user.getIdToken()}`
                },
                body: formData,
            };
            const resp = await fetch('/v2/import/portfolio/screenshot', requestData);

            if (!resp.ok) {
                console.error('Error processing image:', resp);
                return;
            }

            const responseData = await resp.json();

            this.portfolio = { ...responseData['message'] };
            this.portfolio.imported = 'screenshot';
            this.errorMessages = [...responseData['errors']];

            this.updateHasChanges(true);

            // scroll to the results area
            document.getElementById('results').scrollIntoView({ behavior: 'smooth' });
            this.clickQuantity();
        },

        async importPortfolio() {
            // if things are missing, highlight and don't let them continue
            if (this.highlightInvalidCells()) {
                return;
            }

            this.isLoading = false;
            this.modal.newportfolio = true;
        },

        async uploadPortfolioScreenshot() {
            await this.callAPIAuth(' /v1/upload/portfolio/screenshot', { 'filedata': this.fileForDebugging });
            alert("Screenshot received! You can go back to your dashboard and we'll send you an email when everything is done")
        },

        highlightInvalidCells() {
            let errorFound = false;

            const table = document.getElementById('importedAssets');
            const rows = table.querySelectorAll('tbody tr');

            // Regular expression to match date format "Jun 04, 2024"
            const dateRegex = /^[A-Z][a-z]{2} \d{2}, \d{4}$/;

            rows.forEach(row => {
                const cells = row.querySelectorAll('td');

                // Validate Initial column (should be number > 0)
                const initialInput = cells[1].querySelector('input');
                const initialContent = initialInput ? initialInput.value.trim() : '';
                if (isNaN(initialContent) || Number(initialContent) <= 0) {
                    this.startFlashing(initialInput);
                    errorFound = true;
                }

                // Validate Quantity column (should be number > 0)
                const quantityInput = cells[2].querySelector('input');
                const quantityContent = quantityInput ? quantityInput.value.trim() : '';
                if (isNaN(quantityContent) || Number(quantityContent) <= 0) {
                    this.startFlashing(quantityInput);
                    errorFound = true;
                }

                // Validate Price bought column (should be number > 0)
                const priceInput = cells[3].querySelector('input');
                const priceContent = priceInput ? priceInput.value.trim() : '';
                if (isNaN(priceContent) || Number(priceContent) <= 0) {
                    this.startFlashing(priceInput);
                    errorFound = true;
                }

                // Validate Acquired on column (should be a valid date)
                const acquiredOnSelect = cells[4].querySelector('select');
                const acquiredOnContent = acquiredOnSelect ? acquiredOnSelect.value.trim() : '';
                if (acquiredOnContent === "") {
                    this.startFlashing(acquiredOnSelect);
                    errorFound = true;
                }

            });
            return errorFound;
        },

        updateDoA(item, selection) {
            item.DoA = dropdownToDate(selection);
        },

        async updatePortfolioSettings(slug, settings) {
            await this.update(slug, settings);
        },

        /**
         * This is a wrapper to update the local portfolio data & the database.
         */
        async updatePortfolioData() {

        },

        async updateHasChanges(state) {
            this.hasChanges = state;

            // if there are changes and there is autosave enabled, save automatically every 5 seconds                
            // provided no other save is in progress
            if (state) {
                if (this.portfolio.autoSave && !this.isLoading) {
                    const saveInteval = setInterval(() => {
                        this.save();
                        clearInterval(saveInteval);
                    }, 3000); // 1000 milliseconds = 5 seconds                
                }
            }
        },

        async updateSettings() {
            // validate the username
            for (const func of usernameValidation) {
                const res = func(this.settings.account.username);
                if (res != true) return { error: true, message: res };
            }

            // update db instance
            const result = await this.callAPIAuth('/v1/update/settings', { 'settings': this.settings }, true);
            
            // the '/v1/update/settings' endpoint will return an error if the username is already taken or is offensive
            if (result['status'] == 'ok') {
                this.add({ type: 'success', text: '👍 Updated!' });
                //update auth db instance
                const auth = getAuth();
                updateProfile(auth.currentUser, {
                    displayName: this.settings.account.username,
                })
                .then(() => window.location.reload())
                .catch((error) => console.error('Error updating profile:', error));

                return { error: false, message: '' };
            }

            return { error: true, message: result['message'] };
        },

        async getSettings() {
            const result = await this.callAPIAuth('/v1/get/settings');
            const plaid_connections = await this.callAPIAuth(`/v1/plaid/get_connections`);

            if (result) {
                this.settings.notifications.enabled = result.notifications.enabled;
                this.settings.account = result.account;
                this.settings.plan_ids = { ...result.plan_ids }
            }

            if (plaid_connections.results) {
                this.settings.connections = plaid_connections.results.map(pl => ({ id: pl.id, name: pl.institution.name, sync: false }));
            }

        },

        async checkout(price_id) {
            this.isLoading = true;
            try {
                const result = await this.callAPIAuth("/v1/create-checkout-session", { 'price_id': price_id });

                if (result.error) {
                    // Handle the error case
                    alert('Error: ' + result.error);
                }
                else if (result.url) {
                    window.location.href = result.url;
                }

            } catch (error) {
                console.error('Error:', error);
            }
            this.isLoading = false;
        },

        async redirect_to_stripe_portal() {
            this.isLoading = true;
            try {
                const result = await this.callAPIAuth("/v1/create-portal-session");

                if (result.error) {
                    // Handle the error case
                    alert('Error: ' + result.error);
                }
                else if (result.url) {
                    window.location.href = result.url;
                }

            } catch (error) {
                console.error('Error:', error);
            }
            this.isLoading = false;
        },

        getTabFromUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('t') || 'profile';
        },
      
        async getNetWorth(force=false) {
            this.isLoading = true;

            const result = await this.callAPIAuth("/v1/get/netWorth", { force: force });
            if (result) {
                this.netWorth = result['netWorth'];
                this.netWorthLastUpdated = result['lastUpdated'] ? timeAgo(new Date(result['lastUpdated'])) : null;
                this.networthCalculating = result['calculating'] || false;
                
                // If networth is being calculated, start polling
                if (this.networthCalculating) {
                    this.pollNetWorthStatus();
                }
            }

            this.isLoading = false;
        },

        resetPassword(email) {
            sendPasswordResetEmail(firebase_auth, email)
                .then(() => {
                    this.authError = '';
                    this.success = true;
                })
                .catch((error) => {
                    this.authError = getFirebaseErrorMessage(error.message);
                    this.success = false;
                });
        },

        // redirects back to the page and
        // pops up save
        storePreLoginURL(url) {
            localStorage.setItem('preLoginUrl', window.location.href + '?copy')
            window.location.href = url;
        },

        // if redirected and copy, show popup
        showSaveIfRedirectCopy() {
            if (window.location.href.indexOf('?copy') > -1) {
                if (this.notOwner) {
                    this.save();
                }
            }
        },

        // tooltips in /new
        // set to index of the tooltip to show
        // set to null to hide
        // start with 0
        active_tooltip: null,

        // image viewer in /simport controls
        zoom: 150,
        updateZoom(zoom) {
            this.zoom = zoom;
            const image = document.getElementById('imagePreview');
            image.style.transform = `scale(${zoom / 100})`;
        },
        zoomIn() {
            this.zoom = Math.min(2000, this.zoom + 10);
            this.updateZoom(this.zoom);
        },
        zoomOut() {
            this.zoom = Math.max(10, this.zoom - 10);
            this.updateZoom(this.zoom);
        },

        // --- ACHIEVEMENTS ---
        // The achievement system works with "levels"
        // Each new user starts at level 0
        // The user can only proceed to level 1 (can't skip levels)
        // Based on what the achievement for level 1 is (e.g. "Create your first manual portfolio")
        // the updateAchievements function must be called in the relevant function
        // with the `achievement_state` parameter = 1
        // 
        // If the user has already reached level 1, the function will do nothing
        // The function will only update the achievement if the user is at
        // `achievement_state - 1` level
        // 
        // This logic was used in order to keep things simple as it is
        // complex enough to handle the achievements initially set.

        // achievement strings
        achievements: [
            'Create an account',
            'Create your first manual portfolio',
            'Add your first asset',
            'Make a portfolio public',
            // 'Share it on social media - get $5 credit',
            'Copy your first Community portfolio',
            'Check your net-worth',
            'Talk with our AI Agent',
            'Connect your brokerage and import a portfolio',
            // 'Import a portfolio from a bank - get $20 credit!',
            // 'Refer a user - get $5 credit!',

            // For achievements like the following we need to change the system
            // so that some state per achievement is stored
            // Maybe a simple json in a database for the current achievement will suffice

            // 'Check your net-worth for 7 straight days - get $10'
        ],

        user_achievement_state: 0,
        achievementsOpen: false,

        async getAchievementState() {
            // check local storage
            const state = localStorage.getItem('achievement_state');
            if (state !== 'undefined' && state !== null) {
                this.user_achievement_state = state;

                return;
            }

            // get from the server and save to local storage
            try {
                const result = await this.callAPIAuth("/v1/get/achievement-state", {});

                if (result) {
                    // change state
                    this.user_achievement_state = result['achievement_state'];
                    // save to local storage
                    localStorage.setItem('achievement_state', this.user_achievement_state);
                }


            } catch (error) {
                // do nothing
            }
        },

        // call this function to (potentialy) update achievements
        // assumes that all new users start at achievement level 0
        updateAchievements(achievement_state) {
            if (achievement_state - this.user_achievement_state == 1) {
                // open the achievements 
                this.achievementsOpen = true;

                this._update_achievement_state(achievement_state);

                // throw confetti
                confetti({
                    particleCount: 200,
                    spread: 400,
                    origin: { y: 0.5 },
                });
            }
        },

        async _update_achievement_state(new_value) {
            try {
                // @jon sometimes this fails even when the update actually happens - no idea why
                await this.callAPIAuth("/v1/update/achievement-state", { 'achievement_state': new_value });
            } catch (error) {
                // do nothing
            }

            // change state
            this.user_achievement_state = new_value;
            // save to local storage
            localStorage.setItem('achievement_state', new_value);
        },

        clearAchievementState() {
            this.user_achievement_state = 0;
            localStorage.removeItem('achievement_state');
        },

        // ---- REFERRALS -------

        referralEmail: undefined,

        referralInit() {
            // get url params
            const urlParams = new URLSearchParams(window.location.search);
            const referralEmail = urlParams.get('referral');

            if (!referralEmail) {
                // redirect to home
                window.location.href = '/';
                return;
            }

            this.referralEmail = referralEmail;
        },

        async revokePlaidConnection(id) {
            this.modalconfirmDeletePopup = null;
            const results = await this.callAPI(`/v1/plaid/delete_plaid_connection/${this.currentUser.email}/${id}`);

            if (results['result'] == 'nok') {
                this.add({ type: 'error', text: 'An error occurred. Please try again.' });
                return;
            }

            // remove from local state if everything is ok
            this.settings.connections = this.settings.connections.filter((item) => item.id != id);
        },

        async plaid_token() {
            this.popupLoading = 'loading';
            const result = await this.callAPIAuth('/v1/plaid/create-link-token/')
            this.popupLoading = false;

            if (result.needs_upgrade != undefined) {
                this.modal.upgrade.message = ""
                this.modal.upgrade.visible = true;
            }

            else if (result.link_token) {
                Plaid.create({
                    // Create a new link_token to initialize Link
                    token: result.link_token,
                    onLoad: function () {
                    },
                    onSuccess: async (public_token, metadata) => {
                        this.popupLoading = 'importing data';
                        const exchange_result = await this.callAPIAuth('/v1/plaid/create_portfolios/', { public_token: public_token, metadata: metadata });
                        if (exchange_result.public_token_exchange == 'complete') {
                            this.popupLoading = false;
                            window.location.href = "/dashboard/portfolios";

                            // importing a portfolio from a broker is achievement level 7
                            this.updateAchievements(7);

                        }
                        else {
                            this.add({ type: 'error', text: 'Only one connected account is allowed in your plan' });
                        }
                        this.popupLoading = false;
                    },
                    onExit: function (err, metadata) {
                        // The user exited the Link flow.
                        if (err != null) {
                            // The user encountered a Plaid API error prior to exiting.
                        }
                    }
                }).open();
            }
        },

        // ---- CHAT ----
        user_chat_input: '',
        history: [],
        chat_error: '',
        chat_loading: false,
        v2: false,
        models: [
            {
                name: 'FireEngineV1',
                description: 'Slower, Data access',
            },
            {
                name: 'FireEngineV2',
                description: 'Fast, Smarter, no data access',
            }
        ],
        chat_uuid: null,

        generateChatUuid() {
            // generate a uuid if it doesn't exist
            this.chat_uuid = crypto.randomUUID();
        },

        async sendChatMessage(message) {
            if (!message) return;

            this.chat_error = '';

            // we add the user message to the history before the response to render it
            // but we must not pass it to the server
            this.history.push({ 'role': 'user', 'content': message });
            this.chat_loading = true;

            const url = this.v2 ? '/v2/chat' : '/v1/chat';
            const payload = !this.v2 ? {
                'message': message,
                // pass all previous messages to the server
                'history': JSON.stringify(
                    this.history.slice(0, -1).map((msg) => ({ 'role': msg.role, 'content': msg.content }))
                ),
            } : {
                'message': message,
                'chat_id': this.chat_uuid,
            };

            const result = await this.callAPIAuth(url, payload);

            if (result.result === 'nok') {
                this.chat_error = "An error occurred. Please refresh the page and try again.";
                return;
            }

            let event;
            if (result['response']) {
                this.updateAchievements(6);
                this.history.push({
                    'role': 'assistant',
                    'content': result['response'],
                    'cards': result['cards'],
                    'portfolio_table': result['portfolio_table'],
                });

                // render cards & graphs if new are added
                if (result['cards']) {
                    const cards = this.cards['user']
                        .filter((card) => result['cards'].includes(card.slug))
                        .map((card) => ({ ...card, slug: `${card.slug}${this.history.length - 1}` }));
                    // need to wait for the card elements to render before rendering the graphs
                    // also adds a dramatic effect
                    setTimeout(() => this.renderCards({ user: cards }), 500);
                }

                event = new CustomEvent('new-chat-message');
            } else {
                this.chat_error = "An error occurred. Please refresh the page and try again.";
            }

            this.user_chat_input = '';
            this.chat_loading = false;

            if (event)
                window.dispatchEvent(event);
        },

        // ---- BIGGEST MOVERS ----
        biggest_movers: {},
        biggest_movers_report: '',
        biggest_movers_sources: [],
        async _fetchBiggestMovers() {
            const result = await this.callAPIAuth('/v1/biggest-movers');
            if (result['result'] === 'nok') return;
            
            // const result = JSON.parse(resp['result']);
            const biggest_movers = Object.keys(result['biggest_movers']).map(key => ([key, result['biggest_movers'][key]]));
            // sort biggest movers by absolute value
            biggest_movers.sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]));
            const biggest_movers_report = result['report'] || '';
            const biggest_movers_sources = result['sources'] || '';
            
            return {
                biggest_movers,
                biggest_movers_report,
                biggest_movers_sources,
            }
        },
        async getBiggestMovers() {
            // check if there are portfolios in TTLStorage
            if (!(await TTLStorage.has('biggest_movers'))) {
                // if not, fetch and set them
                const result = await this._fetchBiggestMovers();
                // set the portfolios in TTLStorage
                // const ttl = 1  * 60 * 60 * 1000; // 1 hour
                const ttl = 3_600_000;
                await TTLStorage.set(this, 'biggest_movers', result, ttl, this._fetchBiggestMovers);
            }

            // get the portfolios from TTLStorage
            const data = await TTLStorage.get(this, 'biggest_movers');
            this.biggest_movers = data.biggest_movers;
            this.biggest_movers_report = data.biggest_movers_report;
            this.biggest_movers_sources = data.biggest_movers_sources;
        },

        // ---- UTILITY FUNCTIONS ----
        copyToClipboard: copyToClipboard,

        // ---- PWA Popup ----
        isMobile() {
            return /Mobi|Android/i.test(navigator.userAgent);
        },

        isPwa() {
            return window.matchMedia('(display-mode: standalone)').matches ||
                window.navigator.standalone === true;
        },

        // if 'popupOpen' is undefined then this is true, otherwise it's the value of 'popupOpen'
        popupOpen: (localStorage.getItem('popupOpen') || 'true') === 'true',

        updatePopupOpen(val) {
            localStorage.setItem('popupOpen', val);
            this.popupOpen = val;
        },

        // Add this method to your app() component in app.js
        async pollNetWorthStatus() {
            // Initial delay before starting to poll
            await new Promise(resolve => setTimeout(resolve, 2000));
            
            // Set up polling interval
            const pollInterval = setInterval(async () => {
                try {
                    // Call the API to check status
                    const result = await this.callAPIAuth("/v1/get/netWorth", { force: false });
                    
                    // If calculation is complete (networthCalculating is false)
                    if (result && result.calculating === false) {
                        // Clear the interval to stop polling
                        clearInterval(pollInterval);
                        
                        // Update the networth data
                        this.netWorth = result['netWorth'];
                        this.netWorthLastUpdated = result['lastUpdated'] ? timeAgo(new Date(result['lastUpdated'])) : null;
                        this.networthCalculating = false;
                        
                    }
                } catch (error) {
                    console.error("Error polling networth status:", error);
                    // Stop polling on error
                    clearInterval(pollInterval);
                }
            }, 5000); // Poll every 5 seconds
            
            // Set a timeout to eventually stop polling after a maximum time
            setTimeout(() => {
                clearInterval(pollInterval);
            }, 120000); // Stop after 2 minutes maximum
        }
    }
}

function signInButtonUpdater() {
    return {
        init() {
            this.checkAndUpdateButton();
        },
        checkAndUpdateButton() {
            const interval = setInterval(() => {
                const googleButton = document.querySelector('.firebaseui-idp-google .firebaseui-idp-text');
                if (googleButton && googleButton.textContent.includes('Sign in with Google')) {
                    googleButton.textContent = 'Continue with Google';
                    clearInterval(interval);
                }
            }, 500); // Check every 500ms. Adjust the interval as needed.
        }
    }
}


Alpine.start();

export const PORTFOLIO_TTL = 5 * 1000 * 60 * 60; // 5 hours

export function convertDateToYMD(dateStr) {
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to the month because it's zero-based
  const day = dateObj.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function timeAgo(datePast) {
  const now = new Date(); // Current time
  const past = new Date(datePast); // Convert the past date string to a Date object
  const diff = now - past; // Difference in milliseconds

  const seconds = Math.floor(diff / 1000); // Convert milliseconds to seconds
  const minutes = Math.floor(seconds / 60); // Convert seconds to minutes
  const hours = Math.floor(minutes / 60); // Convert minutes to hours

  if (hours > 0) {
    if (hours == 1) {
      return `${hours} hour ago`;
    }
    else {
      return `${hours} hours ago`;
    }
  } else if (minutes > 0) {
    if (minutes == 1) {
      return `${minutes} minute ago`;
    }
    else {
      return `${minutes} minutes ago`;
    }
  } else {
    return `${seconds} seconds ago`;
  }
}

export function formatDateForGraph(tmpdate) {
  const date = new Date(tmpdate);
  const options = { year: '2-digit', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options).replace(' ', ' \'');

  return formattedDate;
}

export function convertMonthYearToDate(dateStr) {
  const [month, year] = dateStr.split(' ');
  const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
  const fullYear = parseInt(year.replace("'", ""), 10) + 2000; // Converts '24 to 2024

  return new Date(fullYear, monthIndex);
}

export function convertMonthDayYearToDate(dateStr) {
  if (dateStr != null && dateStr !== 0 && dateStr !== '') {
    const parts = dateStr.split(' ');
    if (parts.length !== 3) {
      console.error(`Invalid date format: ${dateStr}`);
      return new Date(NaN); // Return an invalid date
    }

    const month = parts[0];
    const day = parseInt(parts[1].replace(',', ''), 10); // Remove comma and parse day
    const year = parseInt(parts[2], 10);

    const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
    if (monthIndex === -1 || isNaN(day) || isNaN(year)) {
      console.error(`Invalid date components: ${dateStr}`);
      return new Date(NaN); // Return an invalid date
    }

    return new Date(year, monthIndex, day);
  } else {
    return new Date(NaN); // Return an invalid date
  }
}

export function getMonthName(monthIndex) {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[monthIndex];
};

export function convertMonthYearToYMD(input) {
  // Define a mapping from month abbreviations to numerical equivalents
  const monthMapping = {
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12'
  };

  // Split the input string to extract the month abbreviation and the year
  const parts = input.split(' ');
  const monthAbbr = parts[0];
  const yearShort = parts[1].replace("'", "");  // Remove the apostrophe

  // Convert the year to a full year (assuming the 21st century)
  const year = '20' + yearShort;

  // Get the numerical equivalent of the month
  const month = monthMapping[monthAbbr];

  // Construct the output string in the format YYYY-MM-DD
  const result = `${year}-${month}-01`;

  return result;
}

export function oneHourPassed(lastRefreshed) {
  if (lastRefreshed == undefined) return true;

  const lastRefreshedDate = new Date(lastRefreshed); // Parse the date string
  const currentDate = new Date(); // Get the current date and time
  const oneHourInMilliseconds = 3600000; // 1 hour in milliseconds

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - lastRefreshedDate;

  // Check if 1 hour has passed
  if (timeDifference >= oneHourInMilliseconds) {
    return true;
  } else {
    return false;
  }
}

export function dropdownToDate(dropdownDate) {
  const currentDate = new Date();
  let calculatedDate;

  switch (dropdownDate) {
    case '1':
      calculatedDate = currentDate;
      break;
    case '2':
      calculatedDate = new Date(currentDate.getFullYear() - 1, 0, 1);
      break;
    case '3':
      calculatedDate = new Date(currentDate.getFullYear() - 2, 0, 1);
      break;
    case '4':
      calculatedDate = new Date(currentDate.getFullYear() - 3, 0, 1);
      break;
    case '5':
      calculatedDate = new Date(currentDate.getFullYear() - 4, 0, 1);
      break;
    default:
      return;
  }

  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return new Intl.DateTimeFormat('en-US', options).format(calculatedDate);
}


window.scrollData = (sliderId) => {
  return {
    scroll: {
      isDown: false,
      startX: null,
      scrollLeft: null
    },
    scrollMethods: {
      ['@mousedown']($event) {
        const slider = document.querySelector(`#${sliderId}`)
        this.scroll.isDown = true
        this.scroll.startX = $event.pageX - slider.offsetLeft
        this.scroll.scrollLeft = slider.scrollLeft
      },
      ['@mouseup.window']() {
        this.scroll.isDown = false
      },
      ['@mousemove.window']($event) {
        if (!this.scroll.isDown) return;

        const slider = document.querySelector(`#${sliderId}`)
        $event.preventDefault()
        const x = $event.pageX - slider.offsetLeft
        const walk = x - this.scroll.startX
        slider.scrollLeft = this.scroll.scrollLeft - walk
      }
    }
  }
}

/**
 * Returns the error message for a Firebase error code.
 *
 * @param {string} errorMessage 
 * @returns 
 */
export function getFirebaseErrorMessage(errorMessage) {
  const errorCode = getFirebaseErrorCode(errorMessage);

  if (!errorCode) return errorMessage;

  return firebaseErrorMessages[errorCode];
}

/**
 * Returns the error code from a Firebase error message.
 * 
 * @param {string} message
 * @returns 
 */
function getFirebaseErrorCode(message) {
  const reg = /\((.*)\)/g
  const matches = message.match(reg);

  if (!matches.length) return null;

  const errorCode = matches[0].replace('(', '').replace(')', '');
  return errorCode;
}

/**
 * Error messages for Firebase authentication errors.
 */
const firebaseErrorMessages = {
  'auth/invalid-email': 'The email and/or password is not correct',
  'auth/invalid-credential': 'The email and/or password is not correct',
  'auth/invalid-login-credentials': 'The email and/or password is not correct',
  'auth/user-not-found': 'The email address is not registered',
  'auth/wrong-password': 'The email and/or password is not correct',
  'auth/email-already-in-use': 'The email address is already in use',
  'auth/weak-password': 'The password must be at least 6 characters long',
}

/**
 * 
 */
export function copyToClipboard(text) {
  // Create a temporary textarea element
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Append it to the document
  document.body.appendChild(textarea);

  // Select the text inside the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the textarea element from the document
  document.body.removeChild(textarea);
}

export const assetSortFunctions = {
  'portfolio': (a, b) => a.portfolioName.localeCompare(b.portfolioName),
  'name': (a, b) => a.name.localeCompare(b.name),
  'quantity': (a, b) => a.quantity - b.quantity,
  'price': (a, b) => a.price - b.price,
  'performance': (a, b) => a.performance - b.performance,
}

export const usernameValidation = [
  (v) => !!v || 'Username is required',
  (v) => (v && v.length >= 3) || 'Username must be at least 3 characters long',
  (v) => (v && v.length <= 15) || 'Username must be at most 15 characters long',
  (v) => /^[a-zA-Z0-9_]*$/.test(v) || 'Username must contain only letters, numbers, and underscores',
];